
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Search from '@/02-molecules/Search.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import ContentBox from '@/03-organisms/Content-box.vue'
import Button from '@/01-atoms/Button.vue'
import ChevronButton from '@/01-atoms/Chevron-button.vue'
import Accordion from '@/02-molecules/Accordion.vue'
import eventHub from '@/event-hub'
import NewMessageModal from '@/03-organisms/New-message-modal.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, FaqServiceKey, TopicServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import SupportUploadDocumentList from '@/03-organisms/Support-upload-document-list.vue'
import { Topic } from '@/services/support/Topic-service'

@Options({
  name: 'Support',
  components: {
    SupportUploadDocumentList,
    LoadingDots,
    IconButton,
    Search,
    TitleDecoration,
    ContentBox,
    Button,
    ChevronButton,
    Accordion,
    NewMessageModal
  }
})
export default class Support extends Vue {
  isLoading = {
    topics: true,
    faq: true
  }

  faqService = inject(FaqServiceKey)
  topicService = inject(TopicServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.loadFaq()
    this.loadTopics()
  }

  clickTopic (item: Topic) {
    eventHub.$emit('open-modal', 'new-message')
    eventHub.$emit('select-topic', item.id)
  }

  loadFaq () {
    this.faqService?.loadFaq()
      .then(() => {
        this.isLoading.faq = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadTopics () {
    this.topicService?.loadTopics()
      .then(() => {
        this.isLoading.topics = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  openNewMessageModal (id: string) {
    eventHub.$emit('open-modal', id)
  }
}
