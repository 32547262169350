<template>
  <div class="support__header mb-l">
    <h1 class="display-1">{{ $t('support.title') }}</h1>
    <IconButton icon="mail" :to="{name: 'Inbox'}" >{{ $t('support.inbox-button') }}</IconButton>
  </div>
  <div class="support__main">
    <div>
      <Search class="mb-l"></Search>
      <TitleDecoration>{{ $t('support.issues.title') }}</TitleDecoration>
      <LoadingDots class="mb-l" v-if="isLoading.topics" />
      <ul class="mb-xl" v-else>
        <li v-for="item in topicService.topics" v-bind:key="item.id" class="mb-m">
          <ChevronButton @click="clickTopic(item)">{{ item.topic }}</ChevronButton>
        </li>
      </ul>
      <TitleDecoration>{{ $t('support.inquiries.title') }}</TitleDecoration>
      <div class="mb-l">
        <SupportUploadDocumentList />
      </div>
      <TitleDecoration>{{ $t('support.faq.title') }}</TitleDecoration>
      <LoadingDots class="mb-l" v-if="isLoading.faq" />
      <ul class="mb-xl" v-else>
        <li v-for="item in faqService.faqList" v-bind:key="item.question" class="mb-m">
          <Accordion>
            <template #title>{{ item.question }}</template>
            {{ item.answer }}
          </Accordion>
        </li>
      </ul>
    </div>
    <div>
      <ContentBox class="mb-l">
        <template #title>{{ $t('support.emergency-box.title') }}</template>
        <IconButton icon="phone" href="tel:00352444444" background="grey-dark" >+352 44 44 44</IconButton>
        <p class="grey-dark fw-700">{{ $t('support.emergency-box.subtitle') }}</p>
        <p class="grey-dark">{{ $t('support.emergency-box.text') }}</p>
        <Button inverse>{{ $t('support.emergency-box.button') }}</Button>
      </ContentBox>
      <ContentBox class="mb-l background--blue-dark-10">
        <template #title>{{ $t('support.contact-box.title') }}</template>
        <p class="grey-dark">{{ $t('support.contact-box.text') }}</p>
        <Button @click="openNewMessageModal('new-message')" inverse>{{ $t('support.contact-box.button') }}</Button>
      </ContentBox>
    </div>
    <NewMessageModal />
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import Search from '@/02-molecules/Search.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import ContentBox from '@/03-organisms/Content-box.vue'
import Button from '@/01-atoms/Button.vue'
import ChevronButton from '@/01-atoms/Chevron-button.vue'
import Accordion from '@/02-molecules/Accordion.vue'
import eventHub from '@/event-hub'
import NewMessageModal from '@/03-organisms/New-message-modal.vue'
import { inject } from 'vue'
import { ErrorHandlerKey, FaqServiceKey, TopicServiceKey } from '@/services/serviceTypes'
import LoadingDots from '@/01-atoms/Loading-dots.vue'
import SupportUploadDocumentList from '@/03-organisms/Support-upload-document-list.vue'
import { Topic } from '@/services/support/Topic-service'

@Options({
  name: 'Support',
  components: {
    SupportUploadDocumentList,
    LoadingDots,
    IconButton,
    Search,
    TitleDecoration,
    ContentBox,
    Button,
    ChevronButton,
    Accordion,
    NewMessageModal
  }
})
export default class Support extends Vue {
  isLoading = {
    topics: true,
    faq: true
  }

  faqService = inject(FaqServiceKey)
  topicService = inject(TopicServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  beforeMount () {
    this.loadFaq()
    this.loadTopics()
  }

  clickTopic (item: Topic) {
    eventHub.$emit('open-modal', 'new-message')
    eventHub.$emit('select-topic', item.id)
  }

  loadFaq () {
    this.faqService?.loadFaq()
      .then(() => {
        this.isLoading.faq = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  loadTopics () {
    this.topicService?.loadTopics()
      .then(() => {
        this.isLoading.topics = false
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  openNewMessageModal (id: string) {
    eventHub.$emit('open-modal', id)
  }
}
</script>
<style lang="scss" scoped>
.support__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support__main {
  display: grid;
  grid-template-columns: 1fr 280px;
  grid-gap: $xmedium;

  @include breakpoint(xmedium down) {
    grid-template-columns: 1fr;
    grid-gap: $medium;
  }
}
</style>
