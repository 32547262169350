/**
 *
 * @param route - this.$route
 */
const getLastUrlSegment = (route: object) => {
  const fullPath = route['fullPath']
  const parts = fullPath.split('/')
  const lastSegment = parts.pop() || parts.pop()
  return lastSegment
}

export default getLastUrlSegment
