<template>
  <BackButton :to="{name: 'Support'}" fallback v-if="showOnResponsive('navigation')" />
  <div class="support-inbox__header" v-if="showOnResponsive('navigation')">
    <h1 class="display-1 mb-l">{{ $t('support.inbox.title') }}</h1>
    <IconButton icon="edit"  @click="openModal('new-message')" class="mb-l">{{ $t('support.inbox.new-message.button') }}</IconButton>
    <NewMessageModal></NewMessageModal>
  </div>
  <div class="support-inbox__grid">
    <div class="support-inbox__chat-previews" v-if="showOnResponsive('navigation')">
      <TitleDecoration v-if="sortedTickets()[4].length">{{ $t('global.latest') }}</TitleDecoration>
      <SupportInboxChatPreview v-for="item in sortedTickets()[4]" :item="item" v-bind:key="item.id" />
      <TitleDecoration v-if="sortedTickets()[3].length">{{ $t('global.last-week') }}</TitleDecoration>
      <SupportInboxChatPreview v-for="item in sortedTickets()[3]" :item="item" v-bind:key="item.id" />
      <TitleDecoration v-if="sortedTickets()[2].length">{{ $t('global.2-weeks-ago') }}</TitleDecoration>
      <SupportInboxChatPreview v-for="item in sortedTickets()[2]" :item="item" v-bind:key="item.id" />
      <TitleDecoration v-if="sortedTickets()[1].length">{{ $t('global.month-ago') }}</TitleDecoration>
      <SupportInboxChatPreview v-for="item in sortedTickets()[1]" :item="item" v-bind:key="item.id" />
      <TitleDecoration v-if="sortedTickets()[0].length">{{ $t('global.year-ago') }}</TitleDecoration>
      <SupportInboxChatPreview v-for="item in sortedTickets()[0]" :item="item" v-bind:key="item.id" />
    </div>
    <router-view v-if="showOnResponsive('main')" @load-tickets="loadTickets"/>
  </div>
</template>
<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import NewMessageModal from '@/03-organisms/New-message-modal.vue'
import SupportInboxChatPreview from '@/02-molecules/Support-inbox-chat-preview.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import router from '@/router'
import getLastUrlSegment from '@/helpers/last-url-segment'
import checkMediaQuery from '@/helpers/media-query'
import variables from '@/scss/js-variables.scss'
import { ErrorHandlerKey, TicketServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import getTimeAgo from '@/helpers/get-time-ago'
import { Ticket } from '@/services/support/Ticket-service'

@Options({
  name: 'Support-inbox',
  components: {
    IconButton,
    TitleDecoration,
    NewMessageModal,
    SupportInboxChatPreview,
    BackButton
  }
})
export default class SupportInbox extends Vue {
  beforeMount () {
    this.loadTickets()
  }

  ticketService = inject(TicketServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  ticketsPerPage = 100

  loadTickets () {
    this.ticketService?.loadTickets(1, this.ticketsPerPage)
      .then(() => {
        if (!checkMediaQuery(`(max-width: ${variables.inboxBreakpoint})`)) {
          this.checkForOpenedMessage()
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  sortedTickets () {
    const newArray = [[], [], [], [], []]
    this.ticketService?.tickets.forEach((ticket: Ticket) => {
      const index = getTimeAgo(ticket.lastMessage.createdAt)!.index
      newArray[index].push(ticket)
    })
    return newArray
  }

  showOnResponsive (element: string) {
    let showContent = true
    if (checkMediaQuery(`(max-width: ${variables.inboxBreakpoint})`)) {
      showContent = false
      if (element === 'navigation' && this.isRoot) {
        showContent = true
      }
      if (element === 'main' && !this.isRoot) {
        showContent = true
      }
    }
    return showContent
  }

  get isRoot () {
    if (getLastUrlSegment(this.$route) === 'inbox') {
      return true
    } else {
      return false
    }
  }

  checkForOpenedMessage () {
    if (getLastUrlSegment(this.$route) === 'inbox') {
      this.openLastMessage()
    }
  }

  openLastMessage () {
    const ticketId = this.ticketService?.tickets[0].id
    router.push(`inbox/${ticketId}`)
  }
}
</script>
<style lang="scss" scoped>
.support-inbox__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.support-inbox__grid {
  display: grid;
  grid-template-columns: 1fr 2fr;
  grid-gap: $xmedium;

  @include breakpoint($inbox-breakpoint down) {
    grid-template-columns: 1fr;
    grid-gap: $medium;
  }
}

.support-inbox__chat-previews {
  height: calc(100vh - 310px);
  overflow-y: auto;

  // create space for the scroll bar
  margin-right: -15px;
  padding-right: 15px;
}
</style>
