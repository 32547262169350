
import { Options, Vue } from 'vue-class-component'
import IconButton from '@/01-atoms/Icon-button.vue'
import TitleDecoration from '@/02-molecules/Title-decoration.vue'
import NewMessageModal from '@/03-organisms/New-message-modal.vue'
import SupportInboxChatPreview from '@/02-molecules/Support-inbox-chat-preview.vue'
import BackButton from '@/02-molecules/Back-button.vue'
import router from '@/router'
import getLastUrlSegment from '@/helpers/last-url-segment'
import checkMediaQuery from '@/helpers/media-query'
import variables from '@/scss/js-variables.scss'
import { ErrorHandlerKey, TicketServiceKey } from '@/services/serviceTypes'
import { inject } from 'vue'
import getTimeAgo from '@/helpers/get-time-ago'
import { Ticket } from '@/services/support/Ticket-service'

@Options({
  name: 'Support-inbox',
  components: {
    IconButton,
    TitleDecoration,
    NewMessageModal,
    SupportInboxChatPreview,
    BackButton
  }
})
export default class SupportInbox extends Vue {
  beforeMount () {
    this.loadTickets()
  }

  ticketService = inject(TicketServiceKey)
  errorHandlerService = inject(ErrorHandlerKey)

  ticketsPerPage = 100

  loadTickets () {
    this.ticketService?.loadTickets(1, this.ticketsPerPage)
      .then(() => {
        if (!checkMediaQuery(`(max-width: ${variables.inboxBreakpoint})`)) {
          this.checkForOpenedMessage()
        }
      })
      .catch((error) => {
        this.errorHandlerService?.loadError(error.response.data)
      })
  }

  sortedTickets () {
    const newArray = [[], [], [], [], []]
    this.ticketService?.tickets.forEach((ticket: Ticket) => {
      const index = getTimeAgo(ticket.lastMessage.createdAt)!.index
      newArray[index].push(ticket)
    })
    return newArray
  }

  showOnResponsive (element: string) {
    let showContent = true
    if (checkMediaQuery(`(max-width: ${variables.inboxBreakpoint})`)) {
      showContent = false
      if (element === 'navigation' && this.isRoot) {
        showContent = true
      }
      if (element === 'main' && !this.isRoot) {
        showContent = true
      }
    }
    return showContent
  }

  get isRoot () {
    if (getLastUrlSegment(this.$route) === 'inbox') {
      return true
    } else {
      return false
    }
  }

  checkForOpenedMessage () {
    if (getLastUrlSegment(this.$route) === 'inbox') {
      this.openLastMessage()
    }
  }

  openLastMessage () {
    const ticketId = this.ticketService?.tickets[0].id
    router.push(`inbox/${ticketId}`)
  }
}
